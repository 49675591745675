import { StatusBar } from "expo-status-bar";
import { StyleSheet, Text, View } from "react-native";
import { Button, Header, Icon, Input } from "react-native-elements";
import tw from "twrnc";
import * as Icons from "react-native-heroicons/solid";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import Addstud from "./screens/addstud";
import Viewstudents from "./screens/viewstudents";
import ImagePickerExample from "./screens/testfileupload";
import { PaperProvider } from "react-native-paper";
import { MD3DarkTheme, MD3LightTheme, DefaultTheme } from "react-native-paper";
import Login from "./screens/login";
export default function App() {
  const stack = createNativeStackNavigator();
  return (
    <PaperProvider theme={DefaultTheme}>
      <NavigationContainer>
        <stack.Navigator>
          <stack.Screen
            name="LoginPage"
            component={Login}
            options={{
              headerShown: false,
            }}
          />
          <stack.Screen
            name="addstud"
            component={Addstud}
            options={{
              headerShown: false,
            }}
          />
          <stack.Screen
            name="viewstudents"
            component={Viewstudents}
            options={{
              headerShown: false,
            }}
          />
        </stack.Navigator>
      </NavigationContainer>
    </PaperProvider>
  );
}
