import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Modal,
  Image,
} from "react-native";
import React, { useEffect, useState, useRef } from "react";
import ViewShot from "react-native-view-shot";
import { saveAs } from "file-saver";
import * as Icons from "react-native-heroicons/solid";
var toBlob = require("data-uri-to-blob");
import tw from "twrnc";
const AttendanceModal = ({
  Dates,
  StudentName,
  setshowattendance,
  StudentProfession,
}) => {
  const ref = useRef();
  const [shoturi, setshoturi] = useState("");
  const [isimagerastered, setisimagerastered] = useState(true);
  const captureimage = () => {
    ref.current.capture().then((uri) => {
      setshoturi(uri);
      setisimagerastered(true);
      let blob = toBlob(uri);
      saveAs(blob, "image.png");
    });
  };
  return (
    <Modal
      style={tw`items-center justify-center bg-gray-600`}
      animationType="fade" // You can choose from 'slide', 'fade', or 'none'
      transparent={true}
    >
      <TouchableOpacity
        style={tw`flex-1 items-center justify-center`}
        onPress={() => {
          setshowattendance(false);
        }}
      >
        <TouchableOpacity
          onPress={() => {
            console.log("clicked");
            captureimage();
          }}
          style={tw`absolute z-40`}
        >
          <Icons.ArrowDownCircleIcon size={50} />
        </TouchableOpacity>

        <ViewShot
          ref={ref}
          options={{
            fileName: "card",
            format: "jpg",
            quality: 0.9,
          }}
        >
          <View
            style={[tw` w-595px bg-white items-start p-4 `, { minHeight: 750 }]}
          >
            <View style={tw` items-start w-full`}>
              <Text
                style={tw` text-xl font-semibold pt-1 font-sans text-left`}
                numberOfLines={1}
              >
                Attendance Sheet
              </Text>
              <Text
                style={tw` text-xl font-semibold pt-1 font-sans text-left`}
                numberOfLines={1}
              >
                Name : {StudentName}
              </Text>
              <Text
                style={tw` text-xl font-semibold pt-1 font-sans text-left`}
                numberOfLines={1}
              >
                Course: {StudentProfession}
              </Text>
            </View>
            <View style={tw``}>
              {Dates[0] ? (
                <Text
                  style={tw` text-xl font-semibold pt-1 pb-2 font-sans text-left`}
                  numberOfLines={1}
                >
                  Number of Attended Days : {Dates.length}
                </Text>
              ) : (
                <Text
                  style={tw` text-xl font-semibold pt-1 pb-2 font-sans text-left`}
                  numberOfLines={1}
                >
                  Number of Attended Days : 0
                </Text>
              )}
              {Dates[0] ? (
                Dates.map((element) => {
                  return (
                    <Text style={tw` text-left font-sans`} key={element}>
                      {element}
                    </Text>
                  );
                })
              ) : (
                <View></View>
              )}
            </View>
          </View>
        </ViewShot>
      </TouchableOpacity>
    </Modal>
  );
};

export default AttendanceModal;
