import { View } from "react-native";
import { Text, TextInput, Button } from "react-native-paper";
import React, { useEffect, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import tw from "twrnc";
const login = () => {
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const navigation = useNavigation();
  useEffect(() => {
    fetch("https://www.carlobay-academy.com/isloggedin", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.loginstate == "success") {
          navigation.navigate("addstud");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <View style={tw` flex-1 bg-gray-200 items-center justify-center`}>
      <View
        style={tw` w-320px bg-white items-center justify-center p-4 gap-y-4 rounded-3xl`}
      >
        <Text style={tw` text-lg`}>Login</Text>
        <TextInput
          spellCheck={false}
          style={tw` w-full`}
          label="username"
          mode="outlined"
          value={username}
          onChangeText={(name) => {
            setusername(name);
          }}
        />
        <TextInput
          spellCheck={false}
          style={tw` w-full`}
          label="password"
          mode="outlined"
          value={password}
          secureTextEntry
          onChangeText={(pass) => {
            setpassword(pass);
          }}
        />
        <Button
          mode="elevated"
          onPress={() => {
            console.log("logging in");
            fetch("https://www.carlobay-academy.com/login", {
              method: "POST",
              credentials: "include",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ username: username, password: password }),
            })
              .then((response) => response.json())
              .then((result) => {
                if (result.loginstate == "success") {
                  navigation.navigate("addstud");
                } else {
                  alert("incorrect username or password");
                }
              })
              .catch((error) => {
                console.log(error);
                alert(error.message);
              });
          }}
        >
          login
        </Button>
      </View>
    </View>
  );
};

export default login;
