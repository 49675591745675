import { View, Text, TouchableOpacity } from "react-native";
import React, { useState } from "react";
import tw from "twrnc";
import * as Icons from "react-native-heroicons/solid";
import { useNavigation, CommonActions } from "@react-navigation/native";
const CustomHeader = () => {
  const navigation = useNavigation();
  return (
    <View
      style={tw` bg-white h-100px justify-between items-center flex-row p-5 w-full`}
    >
      <TouchableOpacity
        onPress={() => {
          navigation.navigate("addstud");
        }}
      >
        <Icons.HomeIcon size={25} />
      </TouchableOpacity>
      <Text>Carlobay</Text>
      <TouchableOpacity
        onPress={() => {
          navigation.navigate("viewstudents", { page: "viewstudents" });
        }}
      >
        <Icons.Bars3Icon size={25} />
      </TouchableOpacity>
    </View>
  );
};

export default CustomHeader;
