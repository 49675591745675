import {
  View,
  ScrollView,
  TouchableOpacity,
  Image,
  Alert,
  Modal,
} from "react-native";
import React, { useEffect, useState } from "react";
import tw from "twrnc";
import CustomHeader from "./CustomHeader";
import * as Icons from "react-native-heroicons/solid";
import * as ImagePicker from "expo-image-picker";
import {
  TextInput,
  Button,
  Text,
  Checkbox,
  Dialog,
  ActivityIndicator,
} from "react-native-paper";
import { useNavigation } from "@react-navigation/native";
import queryString from "query-string";
import { SvgXml } from "react-native-svgxml-web";
import bwipjs from "bwip-js";
import DropDown from "react-native-paper-dropdown";
const Addstud = () => {
  const [visible, setVisible] = React.useState(false);
  const showDialog = () => setVisible(true);
  const hideDialog = () => setVisible(false);
  const [image, setImage] = useState(null);
  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (!result.canceled) {
      setdata({
        name: data.name,
        number: data.number,
        profession: data.profession,
        imagetosend: result.assets[0].uri,
      });
      setImage(result.assets[0].uri);
      /*      fetch("http://localhost:3000/uploadcertificate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(imagetoupload),
      }).then((response) => {
        fetch(
          "http://localhost:3000/getcertificate?" +
            queryString.stringify({
              StudentID: StudentID,
            })
        )
          .then((response) => response.json())
          .then((result) => {
            setImage(result.Certificate);
          });
      }); */
    }
  };
  const [data, setdata] = useState({
    name: "",
    number: "",
    profession: "",
    imagetosend: "",
    type: "",
  });
  const [iserror, setiserror] = useState(false);
  const [modaldetails, modifymodal] = useState(["message", false]);
  const [isChecked, setChecked] = useState(true);
  const positionlist = [
    {
      label: "Makeup",
      value: "Makeup",
    },
    {
      label: "Nail Art",
      value: "Nail Art",
    },
    {
      label: "Hair Dresser",
      value: "Hair Dresser",
    },
    {
      label: "Lash Extensions",
      value: "Lash Extensions",
    },
    {
      label: "Skin Care",
      value: "Skin Care",
    },
    {
      label: "Tattoo",
      value: "Tattoo",
    },
  ];
  const [showDropDown, setShowDropDown] = useState(false);
  const [profession, setprofession] = useState("Makeup");
  const showmodal = (message) => {
    console.log("modal blabla", message);
    modifymodal([message, true]);
  };
  const navigation = useNavigation();

  return (
    <View style={tw` flex-1 `}>
      <CustomHeader />
      <ScrollView contentContainerStyle={{ alignItems: "center", flex: 1 }}>
        <View
          style={tw` items-center bg-white rounded-3xl w-320px bg-white   gap-y-3 m-10 p-5`}
        >
          <Text style={tw` text-xl font-semibold m-4`}>اضافة طالب</Text>
          {/*           <DropDown
            style={tw` w-full`}
            label={"المجال"}
            mode={"outlined"}
            visible={showDropDown}
            showDropDown={() => setShowDropDown(true)}
            onDismiss={() => setShowDropDown(false)}
            value={profession}
            setValue={setprofession}
            list={positionlist}
          /> */}
          <TextInput
            spellCheck={false}
            style={tw` w-full`}
            label="الاسم"
            mode="outlined"
            placeholderTextColor={"gray"}
            onChangeText={(name) => {
              setdata({
                name: name,
                number: data.number,
                profession: data.profession,
                imagetosend: data.imagetosend,
              });
            }}
            value={data.name}
          />

          <TextInput
            spellCheck={false}
            style={tw` w-full`}
            label="العلامة"
            placeholderTextColor={"gray"}
            inputMode="numeric"
            mode="outlined"
            onChangeText={(number) => {
              setdata({
                name: data.name,
                number: number,
                profession: data.profession,
                imagetosend: data.imagetosend,
              });
            }}
            value={data.number}
          />

          {/*           <TextInput
            spellCheck={false}
            right
            placeholderTextColor={"gray"}
            style={tw` text-center  `}
            mode="flat"
            label="المجال"
            onChangeText={(profession) => {
              setdata({
                name: data.name,
                number: data.number,
                profession: profession,
                imagetosend: data.imagetosend,
              });
            }}
            value={data.profession}
          /> */}

          <View
            style={tw` w-1/3 h-10 flex-row text-center items-center justify-center`}
          >
            <Checkbox
              status={isChecked ? "checked" : "unchecked"}
              onPress={() => {
                setChecked(!isChecked);
              }}
            />
            <Text>طالب</Text>
          </View>
          {/*           <View style={tw` flex-col gap-4 m-2 items-center`}>
            <Button
              style={tw` w-30`}
              mode="elevated"
              title="تحميل شهادة"
              onPress={pickImage}
            >
              تحميل شهادة
            </Button>
            {image && (
              <Image
                source={{ uri: image }}
                style={{ width: 50, height: 50 }}
              />
            )}
          </View> */}
          <Button
            style={tw` items-center`}
            mode="elevated"
            onPress={() => {
              setVisible(true);
              if (
                data.name.length >= 4 &&
                data.number.length <= 2 &&
                data.number.length >= 1
              ) {
                let type = "";
                if (isChecked) {
                  data.type = "Student";
                } else {
                  data.type = "Employee";
                }
                fetch("https://www.carlobay-academy.com/addstudent", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  mode: "cors",
                  body: JSON.stringify({
                    name: data.name,
                    number: data.number,
                    profession: profession,
                    imagetosend: data.imagetosend,
                    type: data.type,
                  }),
                })
                  .then((response) => {
                    if (response.ok) {
                      return response.json();
                    }
                  })
                  .then((result) => {
                    setVisible(false);

                    setdata({
                      name: "",
                      number: "",
                      profession: "",
                      imagetosend: "",
                    });
                    setImage("");

                    showmodal(result.message);
                  })
                  .catch((error) => {
                    setVisible(false);
                    setdata({
                      name: "",
                      number: "",
                      profession: "",
                      imagetosend: "",
                    });

                    setImage("");
                    console.log(error);
                    showmodal("خطأ");
                  });
              } else {
                setVisible(false);
                setiserror(true);
              }
            }}
          >
            <Icons.CheckIcon size={13} />
            اضافة
          </Button>

          {iserror ? (
            <Text style={tw` text-sm text-red-600`}>أحد المدخلات غير صحيح</Text>
          ) : (
            <View></View>
          )}
        </View>
      </ScrollView>
      {modaldetails[1] ? (
        <Modal
          style={tw`items-center justify-center flex-1 bg-gray-600 `}
          animationType="fade" // You can choose from 'slide', 'fade', or 'none'
          transparent={false}
        >
          <TouchableOpacity
            style={tw` items-center justify-center flex-1`}
            onPress={() => {
              modifymodal(["", false]);
            }}
          >
            <TouchableOpacity
              onPress={() => {
                modifymodal(["", false]);
              }}
              style={tw` rounded-2xl bg-slate-300 h-25 w-50 text-center items-center justify-center `}
            >
              <Text style={tw`text-xl `}>{modaldetails[0]}</Text>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => {
                modifymodal(["", false]);
              }}
              style={tw` h-10 w-10 m-5`}
            ></TouchableOpacity>
          </TouchableOpacity>
        </Modal>
      ) : (
        <View></View>
      )}
      {visible && (
        <Modal
          style={tw` z-50 items-center justify-center flex-1 `}
          transparent={true}
        >
          <View style={tw` flex-1 items-center justify-center`}>
            <Dialog
              visible={visible}
              onDismiss={hideDialog}
              style={tw` z-50 w-320px`}
            >
              <Dialog.Title></Dialog.Title>
              <Dialog.Content style={tw` text-center m-3`}>
                Please wait
              </Dialog.Content>

              <ActivityIndicator
                style={tw` m-3`}
                animating={true}
                size={"small"}
              />
              <Dialog.Content></Dialog.Content>
              {/*           <Dialog.Actions>
            <Button onPress={hideDialog}>Done</Button>
          </Dialog.Actions> */}
            </Dialog>
          </View>
        </Modal>
      )}
    </View>
  );
};

export default Addstud;
