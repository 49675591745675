import { View, Modal } from "react-native";
import React from "react";
import {
  Button,
  Text,
  ActivityIndicator,
  MD2Colors,
  Portal,
  Dialog,
  Surface,
} from "react-native-paper";
import DropDown from "react-native-paper-dropdown";
import * as ImagePicker from "expo-image-picker";
import { useState, useEffect } from "react";
import tw from "twrnc";
import queryString from "query-string";
import { useNavigation } from "@react-navigation/native";
const certificatepage = ({ StudentID, setcertificatemodal, navigation }) => {
  const [cert_count, set_cert_count] = useState(null);
  useEffect(() => {
    fetch(
      "https://www.carlobay-academy.com/certnum?" +
        queryString.stringify({ StudentID: StudentID })
    )
      .then((response) => response.json())
      .then((result) => {
        //setresults(result.StudentData.reverse());
        console.log(result);
        set_cert_count(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const positionlist = [
    {
      label: "Makeup",
      value: "Makeup",
    },
    {
      label: "Nail Art",
      value: "Nail Art",
    },
    {
      label: "Hair Dresser",
      value: "Hair Dresser",
    },
    {
      label: "Lash Extensions",
      value: "Lash Extensions",
    },
    {
      label: "Skin Care",
      value: "Skin Care",
    },
    {
      label: "Tattoo",
      value: "Tattoo",
    },
  ];
  const certificate_types = [
    {
      label: "Diploma",
      value: "Diploma",
    },
    {
      label: "Masters",
      value: "Masters",
    },
    {
      label: "Professional",
      value: "Professional",
    },
  ];
  const [showDropDown, setShowDropDown] = useState(false);
  const [profession, setprofession] = useState("Makeup");

  const [showDropDown2, setShowDropDown2] = useState(false);
  const [certificate_type, set_certificate_type] = useState("Diploma");

  const [visible, setVisible] = React.useState(false);
  const [visible2, setVisible2] = React.useState([false, ""]);
  const showDialog = () => setVisible(true);
  const hideDialog = () => setVisible(false);
  // const navigation = useNavigation();
  const showDialog2 = (message) => {
    setVisible2([true, message]);
  };
  const hideDialog2 = () => setVisible2([false, ""]);
  const deleteImage = async (type) => {
    showDialog();
    fetch("https://www.carlobay-academy.com/deletecertificate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        profession: profession,
        type: certificate_type,
        StudentID: StudentID,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((result) => {
        hideDialog();

        showDialog2(result.message);
        console.log(result);
      })
      .catch((error) => {
        hideDialog();
        showDialog2(error.message);

        console.log("error");
      });
  };
  const pickImage = async (type) => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });
    const imagetoupload = result.assets[0].uri;
    if (!result.canceled) {
      showDialog();
      console.log("trying to upload");
      fetch("https://www.carlobay-academy.com/uploadcertificate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
          type: certificate_type,
          profession: profession,
          image: imagetoupload,
          StudentID: StudentID,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((result) => {
          hideDialog();

          showDialog2(result.message);
          console.log(result);
        })
        .catch((error) => {
          hideDialog();
          showDialog2(error.message);
          console.log("error");
        });
    }
  };
  const pickImage2 = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });
    const imagetoupload = result.assets[0].uri;
    if (!result.canceled) {
      showDialog();
      console.log("trying to upload");
      fetch("https://www.carlobay-academy.com/uploadpicture", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
          image: imagetoupload,
          StudentID: StudentID,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((result) => {
          hideDialog();

          showDialog2(result.message);
          console.log(result);
        })
        .catch((error) => {
          hideDialog();
          showDialog2(error.message);
          console.log("error");
        });
    }
  };
  return (
    <View style={tw` flex-1 items-center justify-center gap-y-5`}>
      <Button
        style={tw` mt-5`}
        onPress={() => {
          setcertificatemodal(false);
        }}
        mode="elevated"
      >
        اغلاق
      </Button>
      <View style={tw` bg-gray-100 rounded-lg gap-4 p-4 `}>
        {/*         <View style={tw` flex-row text-center items-center justify-center`}>
          {false ? (
            <Text style={tw` text-green-500`}>Diploma تم تحميل الشهادة</Text>
          ) : (
            <Text style={tw` text-red-500`}>Diploma لا يوجد شهادة</Text>
          )}
        </View> */}
        <DropDown
          style={tw` w-full`}
          label={"المجال"}
          mode={"outlined"}
          visible={showDropDown}
          showDropDown={() => setShowDropDown(true)}
          onDismiss={() => setShowDropDown(false)}
          value={profession}
          setValue={setprofession}
          list={positionlist}
        />
        <DropDown
          label={"نوع الشهادة"}
          mode={"outlined"}
          visible={showDropDown2}
          showDropDown={() => setShowDropDown2(true)}
          onDismiss={() => setShowDropDown2(false)}
          value={certificate_type}
          setValue={set_certificate_type}
          list={certificate_types}
        />
        <Button
          mode="elevated"
          title="Diploma تحميل شهادة "
          onPress={() => {
            pickImage2();
          }}
        >
          تحميل صورة شخصية
        </Button>
        <Button
          mode="elevated"
          title="Diploma تحميل شهادة "
          onPress={() => {
            pickImage("diploma");
          }}
        >
          تحميل شهادة
        </Button>
        <Button
          buttonColor="red"
          mode="contained"
          title="Diploma تحميل شهادة "
          onPress={() => {
            deleteImage("diploma");
          }}
        >
          حذف شهادة
        </Button>
        <Surface
          style={tw` rounded-2xl items-center text-center gap-y-1 p-2`}
          mode="flat"
          elevation={4}
        >
          <Text>شهادات</Text>
          {cert_count &&
            Object.keys(cert_count).map((key) => {
              return (
                <View
                  key={key}
                  style={tw` flex-row gap-x-2 items-center justify-center`}
                >
                  <Text>{key}</Text>
                  <Text>شهادة</Text>
                  <Text>{cert_count[key]}</Text>
                </View>
              );
            })}
        </Surface>
      </View>

      {visible && (
        <Modal
          style={tw` z-50 items-center justify-center flex-1 `}
          transparent={true}
        >
          <View style={tw` flex-1 items-center justify-center`}>
            <Dialog
              visible={visible}
              onDismiss={hideDialog}
              style={tw` z-50 w-320px`}
            >
              <Dialog.Title></Dialog.Title>
              <Dialog.Content style={tw` text-center m-3`}>
                Please wait
              </Dialog.Content>

              <ActivityIndicator
                style={tw` m-3`}
                animating={true}
                size={"small"}
              />
              <Dialog.Content></Dialog.Content>
              {/*           <Dialog.Actions>
            <Button onPress={hideDialog}>Done</Button>
          </Dialog.Actions> */}
            </Dialog>
          </View>
        </Modal>
      )}
      {visible2[0] && (
        <Modal
          style={tw` z-50 items-center justify-center flex-1 bg-blue-500 `}
          transparent={true}
        >
          <Dialog
            visible={visible2[0]}
            onDismiss={hideDialog}
            style={tw` z-50 w-320px items-center`}
          >
            <Dialog.Title></Dialog.Title>

            <Dialog.Content>{visible2[1]}</Dialog.Content>
            <Dialog.Actions>
              <Button
                onPress={() => {
                  hideDialog2();
                  setcertificatemodal(false);
                  navigation.navigate("viewstudents", {
                    page: "viewstudents",
                  });
                }}
              >
                Ok{" "}
              </Button>
            </Dialog.Actions>
          </Dialog>
        </Modal>
      )}
    </View>
  );
};

export default certificatepage;
