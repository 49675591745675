import { View, ScrollView } from "react-native";
import { Text, TextInput, Checkbox } from "react-native-paper";
import React, { useEffect, useState } from "react";
import { useIsFocused } from "@react-navigation/native";
import CustomHeader from "./CustomHeader";
import tw from "twrnc";
import StudentCard from "./studentcard";
import { useRoute } from "@react-navigation/native";
import { launchImageLibrary } from "react-native-image-picker";
import { DataTable } from "react-native-paper";
const Viewstudents = () => {
  const [searchvalue, setsearchvalue] = useState("");
  const [searchdatevalue, setsearchdatevalue] = useState("");
  const [searchdatevalue2, setsearchdatevalue2] = useState("");
  const [results, setresults] = useState([]);
  const [isCheckedٍStudents, setCheckedStudents] = useState(true);
  const [isCheckedEmployees, setCheckedEmployees] = useState(true);
  const [isDeleteMode, setDeleteMode] = useState(false);
  useEffect(() => {
    fetch("https://www.carlobay-academy.com/allstudentdata")
      .then((response) => response.json())
      .then((result) => {
        setresults(result.StudentData.reverse());
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [useRoute().params]);

  return (
    <View style={tw` flex-1`}>
      <CustomHeader />
      <ScrollView contentContainerStyle={{ alignItems: "center", flex: 1 }}>
        <View style={tw` gap-y-3 items-center w-full m-4`}>
          <TextInput
            spellCheck={false}
            placeholder="بحث الاسم"
            placeholderTextColor={"gray"}
            onChangeText={(searchtext) => {
              setsearchvalue(searchtext);
            }}
          />

          {/*         <TextInput
          spellCheck={false}
          placeholder="بحث تاريخ التسجيل"
          placeholderTextColor={"gray"}
          onChangeText={(datevalue) => {
            setsearchdatevalue(datevalue);
          }}
        />

        <TextInput
          spellCheck={false}
          placeholder="بحث تاريخ الحضور"
          placeholderTextColor={"gray"}
          onChangeText={(datevalue) => {
            setsearchdatevalue2(datevalue);
          }}
        /> */}
          <View style={tw` flex-row`}>
            <View style={tw` flex-row items-center m-2`}>
              <Checkbox
                status={isCheckedٍStudents ? "checked" : "unchecked"}
                onPress={() => {
                  setCheckedStudents(!isCheckedٍStudents);
                }}
              />
              <Text>طلاب</Text>
            </View>
            <View style={tw` flex-row items-center m-2`}>
              <Checkbox
                status={isCheckedEmployees ? "checked" : "unchecked"}
                onPress={() => {
                  setCheckedEmployees(!isCheckedEmployees);
                }}
              />
              <Text>موظفين</Text>
            </View>
            <View style={tw` flex-row items-center m-2`}>
              <Checkbox
                status={isDeleteMode ? "checked" : "unchecked"}
                onPress={() => {
                  setDeleteMode(!isDeleteMode);
                }}
              />
              <Text>تشغيل وضع الحذف </Text>
            </View>
          </View>
        </View>
        <View style={tw` flex-1 items-center`}>
          {results &&
            results
              .filter((element) =>
                element.StudentName.toLowerCase().includes(
                  searchvalue.trim().toLowerCase()
                )
              )
              .filter((element) => {
                let condition1 = false;
                let condition2 = false;
                console.log(element.stype);
                if (isCheckedٍStudents) {
                  if (element.type == "Student") {
                    condition1 = true;
                  }
                }
                if (isCheckedEmployees) {
                  if (element.type == "Employee") {
                    condition2 = true;
                  }
                }
                return condition1 || condition2;
              })
              .filter((element) =>
                element.registration_Date.includes(searchdatevalue.trim())
              )
              .filter((element) => {
                let condition = false;

                if (searchdatevalue2.trim() == "") {
                  return true;
                } else {
                  if (element.AttendanceDate) {
                    if (element.AttendanceDate[0]) {
                      element.AttendanceDate.forEach((innerelement) => {
                        if (innerelement.includes(searchdatevalue2.trim())) {
                          condition = true;
                        }
                      });
                    }
                  }
                }
                return condition;
              })
              .map((element) => (
                <StudentCard
                  nestedScrollEnabled={true}
                  key={element.StudentID}
                  StudentID={element.StudentID}
                  StudentName={element.StudentName}
                  StudentProfession={element.StudentProfession}
                  Dates={element.AttendanceDate}
                  phone_number={element.phone_number}
                  registration_date={element.registration_Date}
                  type={element.type}
                  firstrow={false}
                  deletemode={isDeleteMode}
                />
              ))}
        </View>
        {/*       {results.map((element) => (
        <StudentCard
          nestedScrollEnabled={true}
          key={element.StudentID}
          StudentID={element.StudentID}
          StudentName={element.StudentName}
          StudentProfession={element.StudentProfession}
          Dates={element.AttendanceDate}
          firstrow={false}
        />
      ))} */}
      </ScrollView>
    </View>
  );
};

export default Viewstudents;
