import {
  View,
  ScrollView,
  TouchableOpacity,
  Image,
  Linking,
  Modal,
} from "react-native";
import {
  Text,
  TextInput,
  Checkbox,
  Button,
  Card,
  Portal,
  Dialog,
  Modal as PaperModal,
} from "react-native-paper";
import Certificatepage from "./certificatepage";
import { DataTable } from "react-native-paper";
import React, { useEffect, useState, useRef } from "react";
import tw from "twrnc";
import { SvgXml } from "react-native-svgxml-web";
import bwipjs from "bwip-js";
import * as Icons from "react-native-heroicons/solid";
import ViewShot from "react-native-view-shot";
import { saveAs } from "file-saver";
var toBlob = require("data-uri-to-blob");
import ImagePickerExample from "./testfileupload";
import { AntDesign } from "react-native-vector-icons";
import { Feather } from "react-native-vector-icons";
import { MaterialCommunityIcons } from "react-native-vector-icons";
import AttendanceModal from "./attendancemodal";
import QRCode from "react-qr-code";
import queryString from "query-string";
import { useNavigation } from "@react-navigation/native";
import DropDown from "react-native-paper-dropdown";
import * as Clipboard from "expo-clipboard";
const StudentCard = ({
  StudentID,
  StudentName,
  StudentProfession,
  Dates,
  phone_number,
  registration_date,
  type,
  deletemode,
}) => {
  const handleClick = (url) => {
    Linking.openURL(url).catch((err) =>
      console.error("Error opening URL: ", err)
    );
  };
  const [showattendance, setshowattendance] = useState(false);
  const [certificatemodal, setcertificatemodal] = useState(false);
  const [visible2, setVisible2] = React.useState([false, ""]);
  const showDialog2 = (message) => {
    setVisible2([true, message]);
  };
  const hideDialog2 = () => setVisible2([false, ""]);
  const [editmodal, seteditmodal] = useState(false);
  const [showcard, setshowcard] = useState(false);
  const [newMark, setnewmark] = useState(phone_number);
  const [newName, setnewname] = useState(StudentName);
  const [newStudentProfession, setnewprofession] = useState(StudentProfession);
  const copyToClipboard = (url) => {
    console.log("copying");
    Clipboard.setStringAsync(url);
    alert("تم النسخ");
  };
  const positionlist = [
    {
      label: "Makeup",
      value: "Makeup",
    },
    {
      label: "Nail Art",
      value: "Nail Art",
    },
    {
      label: "Hair Dresser",
      value: "Hair Dresser",
    },
    {
      label: "Lash Extensions",
      value: "Lash Extensions",
    },
    {
      label: "Skin Care",
      value: "Skin Care",
    },
  ];
  const [showDropDown, setShowDropDown] = useState(false);
  const [profession, setprofession] = useState("Makeup");
  const navigation = useNavigation();
  let barcode = bwipjs.toSVG({
    bcid: "code128", // Barcode type
    text: String(StudentID), // Text to encode
    height: 12, // Bar height, in millimeters
    includetext: false, // Show human-readable text
    textxalign: "center", // Always good to set this
    textcolor: "000000", // Red text
  });
  const ref = useRef();
  const [shoturi, setshoturi] = useState("");
  const [isimagerastered, setisimagerastered] = useState(true);
  const captureimage = () => {
    ref.current.capture().then((uri) => {
      setshoturi(uri);
      setisimagerastered(true);
      let blob = toBlob(uri);
      saveAs(blob, "image.png");
    });
  };
  return (
    <View style={tw` m-2`}>
      <Card style={tw`  w-320px`}>
        <Card.Content>
          <Text style={tw` font-semibold`} variant="titleMedium">
            {StudentName}
          </Text>
          {/*       <Text style={tw``} variant="bodyMedium">
            {StudentProfession}
          </Text> */}
          <Text style={tw` `} variant="bodyMedium">
            Mark : {phone_number}
          </Text>

          {/*           <View style={tw` flex-row items-center justify-center gap-x-3`}>
            <Text>STID:</Text>
            <TextInput style={tw`h-6`} value={StudentID} disabled={true} />
          </View>
          <View style={tw` flex-row items-center justify-center gap-x-3`}>
            <Text>URL :</Text>
            <TextInput
              style={tw`h-6`}
              value={
                "https://www.carlobay-academy.com/certificate?" +
                queryString.stringify({
                  StudentID: StudentID,
                })
              }
              disabled={true}
            />
          </View> */}
        </Card.Content>
        <Card.Actions>
          <View style={tw` flex-col gap-y-2`}>
            <Button
              mode="elevated"
              style={tw` items-center`}
              onPress={() => {
                setshowcard(true);
              }}
            >
              البطاقة
            </Button>

            {deletemode && (
              <Button
                buttonColor="red"
                mode="contained"
                disabled={!deletemode}
                onPress={() => {
                  fetch(
                    "https://www.carlobay-academy.com/deletestudent?" +
                      queryString.stringify({
                        StudentID: StudentID,
                        password: "fhds2131243###fdasf#*LQ?",
                      })
                  ).then((response) => {
                    if (response.ok) {
                      navigation.navigate("viewstudents", {
                        page: "viewstudents",
                      });
                    }
                  });
                }}
              >
                حذف
              </Button>
            )}
          </View>

          <Button
            onPress={() => {
              seteditmodal(true);
            }}
          >
            تعديل
          </Button>
          <Button
            onPress={() => {
              setcertificatemodal(true);
            }}
          >
            شهادات
          </Button>
        </Card.Actions>
      </Card>
      {/*         <View style={tw` flex-col  `}>
          <View style={tw` flex-row`}>
            <Text style={tw` font-semibold text-blue-500`}>{StudentName}</Text>
          </View>
          <View style={tw` flex-row `}>
            <Text style={tw` font-semibold text-blue-500`}>
              Position : {StudentProfession}
            </Text>
          </View>
          <View style={tw` flex-row `}>
            <Text style={tw` font-semibold text-blue-500`}>
              {registration_date}
            </Text>
          </View>
        </View> */}

      {deletemode && (
        <DataTable.Cell
          style={tw` items-center justify-center `}
        ></DataTable.Cell>
      )}
      {/* <ImagePickerExample StudentID={StudentID} /> */}

      {showcard ? (
        <Modal
          style={tw`items-center justify-center bg-gray-600`}
          animationType="fade" // You can choose from 'slide', 'fade', or 'none'
          transparent={true}
        >
          <TouchableOpacity
            style={tw`flex-1 items-center justify-center`}
            onPress={() => {
              setshowcard(false);
            }}
          >
            <View
              style={tw` z-40 absolute flex-1 items-center justify-center gap-y-3`}
            >
              <View style={tw` m-3 items-center gap-y-2 justify-center`}>
                <Text style={tw` font-semibold `}>QR Code</Text>
                <QRCode
                  value={
                    "https://www.carlobay-academy.com/certificate?" +
                    queryString.stringify({
                      StudentID: StudentID,
                    })
                  }
                  bgColor=" #00000000"
                  style={tw`  z-4 w-25 h-25 `}
                />
              </View>
              <Button
                mode="elevated"
                onPress={() => {
                  handleClick(
                    "https://www.carlobay-academy.com/certificate?" +
                      queryString.stringify({
                        StudentID: StudentID,
                      })
                  );
                }}
              >
                جرب الموقع
              </Button>
              <Button
                mode="elevated"
                onPress={() => {
                  console.log("clicked");
                  captureimage();
                }}
              >
                تنزيل البطاقة
              </Button>
              <View style={tw` flex-row gap-x-2`}>
                <Button
                  title="Copy"
                  mode="elevated"
                  onPress={() => {
                    copyToClipboard(
                      "https://www.carlobay-academy.com/certificate?" +
                        queryString.stringify({
                          StudentID: StudentID,
                        })
                    );
                  }}
                >
                  نسخ رابط
                </Button>
                <Button
                  title="Copy"
                  mode="elevated"
                  onPress={() => {
                    copyToClipboard(StudentID);
                  }}
                >
                  ID نسخ
                </Button>
              </View>
              <Button
                title="Copy"
                mode="elevated"
                onPress={() => {
                  copyToClipboard(StudentName);
                }}
              >
                نسخ اسم
              </Button>

              <Button
                mode="elevated"
                onPress={() => {
                  setshowcard(false);
                }}
              >
                اغلاق
              </Button>
            </View>
            <View style={tw` absolute z-30 w-full h-full bg-white`}></View>
            <ViewShot
              ref={ref}
              options={{
                fileName: "card",
                format: "jpg",
                quality: 0.9,
              }}
            >
              <View style={tw`  w-1269px h-736px`}>
                <View style={tw`absolute z-4 w-full h-full`}>
                  <Text
                    style={tw`absolute z-4 text-4xl italic font-semibold top-32 left-70`}
                  >
                    {StudentName}
                  </Text>
                  <Text
                    style={tw`absolute z-4 text-4xl italic font-semibold top-51 left-70`}
                  >
                    {StudentProfession}
                  </Text>
                  <Text
                    style={tw`absolute z-4 text-4xl italic font-semibold top-68 left-70`}
                  >
                    {StudentID}
                  </Text>
                  <View style={tw`absolute top-90 left-30`}>
                    <QRCode
                      value={
                        "https://www.carlobay-academy.com/certificate?" +
                        queryString.stringify({
                          StudentID: StudentID,
                        })
                      }
                      bgColor=" #00000000"
                      style={tw`  z-4 w-55 h-55 `}
                    />
                  </View>
                </View>

                <Image
                  style={tw`absolute w-1269px h-736px `}
                  source={require("../assets/photo_2024-01-21_16-06-49.jpg")}
                />
              </View>
            </ViewShot>
          </TouchableOpacity>
        </Modal>
      ) : (
        <View></View>
      )}
      {showattendance && (
        <AttendanceModal
          StudentName={StudentName}
          Dates={Dates}
          StudentProfession={StudentProfession}
          setshowattendance={setshowattendance}
        />
      )}
      {editmodal && (
        <Modal transparent={false}>
          <View style={tw` items-center justify-center w-full h-full `}>
            <View
              style={tw` w-320px p-4 bg-white rounded-2xl gap-y-4 items-center justify-center -z-10 `}
            >
              <Text style={tw` text-base`}>تعديل طالب</Text>
              <TextInput
                spellCheck={false}
                style={tw` w-full`}
                label="العلامة"
                placeholderTextColor={"gray"}
                inputMode="numeric"
                mode="outlined"
                onChangeText={(number) => {
                  setnewmark(number);
                }}
                value={newMark}
              />
              <TextInput
                spellCheck={false}
                style={tw` w-full`}
                label="الاسم"
                mode="outlined"
                placeholderTextColor={"gray"}
                onChangeText={(name) => {
                  setnewname(name);
                }}
                value={newName}
              />
              <TextInput
                spellCheck={false}
                style={tw` w-full`}
                label="المجال"
                mode="outlined"
                placeholderTextColor={"gray"}
                onChangeText={(newprof) => {
                  setnewprofession(newprof);
                }}
                value={newStudentProfession}
              />

              <Button
                onPress={() => {
                  const datatoupdate = {
                    StudentID: StudentID,
                    StudentProfession: newStudentProfession,
                    mark: newMark,
                    StudentName: newName,
                  };
                  console.log(datatoupdate);
                  fetch("https://www.carlobay-academy.com/updatestudent", {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    mode: "cors",

                    body: JSON.stringify(datatoupdate),
                  })
                    .then((response) => {
                      if (response.ok) {
                        return response.json();
                      }
                    })
                    .then((result) => {
                      showDialog2(result.message);
                      console.log(result);
                    })
                    .catch((error) => {
                      showDialog2(error.message);
                      console.log("error");
                    });
                }}
                mode="elevated"
              >
                تعديل
              </Button>
              <Button
                mode="elevated"
                onPress={() => {
                  console.log(StudentID);
                  seteditmodal(false);
                }}
              >
                اغلاق
              </Button>
            </View>
          </View>
        </Modal>
      )}

      {certificatemodal && (
        <Portal>
          <PaperModal
            visible={true}
            transparent={false}
            style={tw` items-center justify-center `}
          >
            <ScrollView style={tw` flex-1`}>
              <Certificatepage
                StudentID={StudentID}
                setcertificatemodal={setcertificatemodal}
                navigation={navigation}
              />
            </ScrollView>
          </PaperModal>
        </Portal>
      )}

      {visible2[0] && (
        <Modal
          style={tw` z-50 items-center justify-center flex-1 bg-blue-500 `}
          transparent={true}
        >
          <Dialog visible={visible2[0]} style={tw` z-50 w-320px items-center`}>
            <Dialog.Title></Dialog.Title>

            <Dialog.Content>{visible2[1]}</Dialog.Content>
            <Dialog.Actions>
              <Button
                onPress={() => {
                  hideDialog2();
                  seteditmodal(false);
                  navigation.navigate("viewstudents", {
                    page: "viewstudents",
                  });
                }}
              >
                Ok{" "}
              </Button>
            </Dialog.Actions>
          </Dialog>
        </Modal>
      )}
    </View>
  );
};

export default StudentCard;
